'use strict';

/* exported
 stationsCtrl
  */

function stationsCtrl($scope, stations, appConfig, $location) {
  $scope.stations = stations;
  $scope.filters = {};

  /**
   * Paging
   */
  // On init if page is above 1
  var search = $location.search();

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.stations.meta.total;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;
    var filter = {offset: offset, limit: limit, type: $scope.filters.type};

    if ($scope.filters.city) {
      filter.city = $scope.filters.city;
    }

    $scope.stations.getList(filter).then(function(stations) {
      $scope.stations = stations;
    });
  };

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  /**
   * Filters
   */
  $scope.$on('filters.city', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.stations.getList($scope.filters).then(function(stations) {

      $scope.currentPage = 1;
      $scope.stations = stations;
      $scope.totalItems = $scope.stations.meta.total;
    });
  });
  $scope.$on('filters.type', function(event, data) {
    $scope.filters.type = data === 'all' ? undefined : data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.stations.getList($scope.filters).then(function(stations) {

      $scope.currentPage = 1;
      $scope.stations = stations;
      $scope.totalItems = $scope.stations.meta.total;
    });
  });
  $scope.$on('filters.locationType', function(event, data) {
    $scope.filters.locationType = data === 'all' ? undefined : data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.stations.getList($scope.filters).then(function(stations) {

      $scope.currentPage = 1;
      $scope.stations = stations;
      $scope.totalItems = $scope.stations.meta.total;
    });
  });
  $scope.$on('filters.name', function(event, data) {

    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.stations.getList($scope.filters).then(function(stations) {

      $scope.currentPage = 1;
      $scope.stations = stations;
      $scope.totalItems = $scope.stations.meta.total;
    });
  });
}

'use strict';

/* exported
  menuStationsCtrl
 */
function menuStationsCtrl($scope, $rootScope, $location) {
  $scope.filters = {type: { label: 'All', id: 'all' }, locationType: { label: 'All', id: 'all' }};
  $scope.geometricTypes = [
    { label: 'All', id: 'all' },
    { label: 'Point', id: 'address' },
    { label: 'Area', id: 'area' },
  ];
  $scope.locationTypes = [
    { label: 'All', id: 'all' },
    { label: 'Airport', id: 'airport' },
  ];
  // On init if country is selected display it
  var search = $location.search();

  if (search.city) {
    $scope.filters.city = {_id: search.city};
  }

  if (search.name) {
    $scope.filters.name = search.name;
  }

  $scope.$watch('filters.city', function(city) {
    $rootScope.$broadcast('filters.city', (city || {})._id);

    var search = $location.search();
    search.city = (city || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    var search = $location.search();
    search.name = name;
    $location.search(search);
  });
  
  $scope.$watch('filters.type', function(type) {
    $rootScope.$broadcast('filters.type', type.id);
    var search = $location.search();
    search.type = type.id;
    $location.search(search);
  });

  $scope.$watch('filters.locationType', function(type) {
    $rootScope.$broadcast('filters.locationType', type.id);
    var search = $location.search();
    search.locationType = type.id;
    $location.search(search);
  });

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('filters.city', null);
    $rootScope.$broadcast('filters.type', 'all');
    $rootScope.$broadcast('filters.locationType', 'all');
    $location.search({});
  };
}

'use strict';

/* exported
 stationViewCtrl
 */

function stationViewCtrl($scope, station, appConfig, mapService) {
  $scope.station = station;

  function initAreaMap() {
    if (station && station.type === 'area') {
      function callback(map) {
        $scope.map = map;
        $scope.map.on('load', () => {
          $scope.polygon = mapService.drawStationPolygon($scope.map, $scope.station, 'station_polygon');
        });
      }
      mapService.createMap('mapbox', callback);
    }
  }
  initAreaMap();
  $scope.getDisplayWithUpperCase = function(label){
    return label[0].toUpperCase() + label.slice(1)
  }
  $scope.getGeometricTypeDisplayName = function(type){
    if(type ==='address') return 'Point';
    
    return this.getDisplayWithUpperCase(type);

  }
  $scope.getMapStaticSrc = function() {
    if ($scope.staticMapUrl) { 
      return $scope.staticMapUrl;
    }
    $scope.staticMapUrl = mapService.getStaticMapSrc({
      loc: station.loc,
    });
    return $scope.staticMapUrl;
  }

  $scope.getThumbnailStyle = function (image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image._id + '.' + image.ext + '?width=600';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };
}

"use strict";

/* exported
 stationEditCtrl
 */

function stationEditCtrl($rootScope, $scope, station, cities, zones, Restangular, $state, mapService, user) {
  $scope.isNew = !station;
  $scope.station = station || {};
  $scope.zones = zones;
  $scope.user = user;
  $scope.allowEdit = user.additionalScopes.includes('data-entry');
  $scope.originalStationType = (station && station.type) || 'address';
  $scope.filteredZones = zones;
  $scope.cities = cities;
  $scope.isOriginalManualPolygon = station && station.type === 'area' && station.isManualPolygon;
  $scope.filter = { stationType: $scope.station.type ? $scope.station.type : 'address' };
  $scope.areaDimensionsBy = 0;
  $scope.initialAreaName = station && station.areaName;
  $scope.locationTypes = [
    {value:'airport',display:'Airport'},
  ];

  if ($scope.initialAreaName) {
    $scope.areaNameSearch = $scope.initialAreaName;
  }

  $scope.areaCreated = function (e) {
    $scope.polygon = e.features[0].geometry.coordinates;
    $scope.station.isManualPolygon = true;
    mapService.deleteAllShapesExceptLastOne($scope.draw);
    $scope.removeInitialPolygons();
  }

  $scope.areaDeleted = function(e) {
    const data = $scope.draw.getAll();
    $scope.station.isManualPolygon = $scope.isOriginalManualPolygon;
    if (!data.feature || data.feature.length === 0) {
      if ($scope.originalStationType === 'address') {
        $scope.polygonForNewStation = undefined;
        $scope.clearAreaInput();
      }else if ($scope.polygonForNewStation) {
        mapService.drawPolygon($scope.map, $scope.polygonForNewStation, 'station_polygon');
      } else {
        $scope.polygon = mapService.drawStationPolygon($scope.map, $scope.station, 'station_polygon');
      }
    }
  }

  $scope.isValidLoc = function() {
    return $scope.station && $scope.station.loc && $scope.station.loc[0] !== 0 && $scope.station.loc[1] !==0;
  }

  function onMapInitialized(map) {
    $scope.map = map;
    if ($scope.station && $scope.station.type === 'area') {
      $scope.draw = mapService.createMapboxDraw();
      $scope.map.on('draw.create', $scope.areaCreated);
      $scope.map.on('draw.delete', $scope.areaDeleted);
      $scope.map.on('load', () => {
        $scope.map.addControl($scope.draw);
        if ($scope.originalStationType === 'area') {
          $scope.polygon = mapService.drawStationPolygon($scope.map, $scope.station, 'station_polygon');
        }
      });
    } else if ($scope.station && $scope.station.type === 'address' && $scope.isValidLoc()) {
      new mapboxgl.Marker()
      .setLngLat([$scope.station.loc[0], $scope.station.loc[1]])
      .addTo($scope.map);
      $scope.map.flyTo({
        center: [$scope.station.loc[0], $scope.station.loc[1]],
        essential: true,
      });
    }
  }

  function initMap() {
    let mapDOM = document.getElementById('mapbox');
    if (mapDOM) {
      if ($scope.map) {
        mapService.removeAllSources($scope.map);
        $scope.map.remove();
        mapDOM = mapService.createMapDOM('map-container', 'mapbox', '(station.type === "address" && isValidLoc()) || (station.areaName)');
      }
      mapService.createMap('mapbox', onMapInitialized);
    }
  }

  initMap();
  setTimeout(() => {
    if ($scope.filter.stationType === 'area') {
      $scope.filter.stationType = "area";
      $scope.station.type = "area";
    } else {
      $scope.filter.stationType = "address";
      $scope.station.type = "address";
    }
  }, 1000);


    const input = document.getElementById("pac-input");
    const options = {
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.unbind("bounds");
    autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });

    $scope.removeInitialPolygons = function () {
      mapService.removePolygon($scope.map, 'station_polygon');
      mapService.removePolygon($scope.map, 'place_polygon');
    }

    autocomplete.addListener("place_changed", () => {
      if ($scope.map) {
        const place = autocomplete.getPlace();
        $scope.station.areaName = document.getElementById("pac-input").value;
        const poly = mapService.getPolygonFromGooglePlace(place);
        // delete all polygons from the map
        $scope.removeInitialPolygons();
        mapService.deleteAllShapes($scope.draw);
        // draw new polygon
        $scope.polygon = poly;
        if ($scope.isNew || $scope.originalStationType === 'address') {
          $scope.polygonForNewStation = poly;
        }
        mapService.drawPolygon($scope.map, poly, 'place_polygon');
        // zoom map to selected place
        $scope.map.flyTo({
          center: [place.geometry.location.lng(), place.geometry.location.lat()],
          essential: true,
        });
        $scope.$apply();
      }
    });

    $scope.populateZone = function () {
      $scope.station.zone = $scope.zones.find(function (z) { return z.slug === $scope.station.zoneSlug });
    }
    $scope.populateZone();
    $scope.populateLocationType = function () {
      $scope.station.locationType = $scope.locationTypes.find(function (type) { return type.value === $scope.station.locationType });
    }
    $scope.populateLocationType();
    $scope.refreshCity = function (search) {
      return Restangular.all('cities').getList({ name: search })
        .then(function (cities) {
          $scope.cities = cities;
        });
    };

    $scope.clearAreaInput = function() {
      $scope.station.areaName = null;
      $scope.areaNameSearch = null;
      document.getElementById("pac-input").value = '';
    }

    $scope.toggleStationType = () => {
      if ($scope.filter.stationType === 'address') {
        $scope.filter.stationType = "area";
        $scope.station.type = "area";
      } else {
        $scope.filter.stationType = "address";
        $scope.station.type = "address";
        $scope.station.isManualPolygon = false;
        if ($scope.isNew || !$scope.initialAreaName) {
          $scope.clearAreaInput();
        }
      }
      initMap();
    };

    const refreshZones = (search) => {
      $scope.filteredZones = $scope.zones.filter(function (zone) {
        return zone.name && zone.name.toLowerCase().includes(search.toLowerCase());
      })
    };
    $scope.refreshZones = _.debounce(refreshZones, 200);
    $scope.save = function () {
      if (!$scope.allowEdit) {
        return $rootScope.$emit('notify', {type: 'error', title: '', message: 'You are not authorized to make these changes.' });
      }
      $scope.globalError = null;

      if (!$scope.station.zone) {
        $scope.station.zoneDeletionFlag = true;
      }
      if (!$scope.station.locationType) {
        $scope.station.locationTypeDeletionFlag = true;
      }

      if ($scope.station.type === 'address' && $scope.station.polygon) {
          $scope.station.polygon = undefined;
      } else if ($scope.station.type === 'area' && $scope.polygon) {
        $scope.station.polygon = {
          type: 'Polygon',
          coordinates: $scope.polygon,
        };
      }
      if (typeof $scope.station.isManualPolygon === 'undefined') {
        $scope.station.isManualPolygon = false;
      };
      $scope.station.zoneSlug = $scope.station.zone ? $scope.station.zone.slug : null;
      $scope.station.zone = $scope.station.zone ? $scope.station.zone.name : null;
      $scope.station.locationType = $scope.station.locationType ? $scope.station.locationType.value : null;
      var promise = $scope.station._id ? $scope.station.put() : Restangular.all('stations').post($scope.station);

      promise
        .then(function (station) {
          $scope.$emit("notify", {
            type: "success",
            title: "Create station",
            message: "Station created with Success",
          });
          $scope.station = station;
          $scope.populateZone();
          $scope.populateLocationType();
          $state.go('root.editStation', { stationId: $scope.station._id });
        })
        .catch(function (err) {
          const errMessage = (err.data && err.data.message) || '';
          $scope.$emit("notify", {
            type: "error",
            title: "Create station",
            message: "Failed to create station " + errMessage,
          });

          $scope.globalError = err.data.message;
        });
    };
  }

'use strict';

/*
exported
stationsSelectorDirective
 */

function stationsSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/stations/stations-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      city: '=city',
      label: '@label',
      disabled: '=disabled'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};

      var initialFilter = {};

      if (scope.city) {
        initialFilter.city = scope.city;
      }

      Restangular
        .all('stations')
        .getList(initialFilter)
        .then(function(stations) {
          scope.stations = stations;
        });

      // End of init

      scope.$watch('city', function(city) {
        if (!city) {
          return;
        }

        Restangular
          .all('stations')
          .getList({city: city})
          .then(function(stations) {
            scope.stations = stations;
          });
      });

      ngModelCtrl.$validators.required = function(modelValue) {
        return !!(modelValue && modelValue._id);
      };


      // From model to view
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {station: modelValue};
      });

      // From view to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.station;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.station && !ngModelCtrl.$viewValue.name) {
          var station = ngModelCtrl.$viewValue.station;

          Restangular
            .one('stations', station._id)
            .get()
            .then(function(station) {
              scope.selected.station = station;
            })
            .catch(function() {
              scope.selected.station = null;
            });
        } else {
          scope.selected.station = ngModelCtrl.$viewValue.station;
        }
      };

      // Update model
      scope.updateStation = function(station) {
        ngModelCtrl.$setViewValue({station: station});
      };

      // Refresh stations list on search
      scope.refreshStationsList = function(search) {
        if (!search) {
          return;
        }

        Restangular
          .all('stations')
          .getList({name: search})
          .then(function(stations) {
            scope.stations = stations;
          });
      };
    }
  };
}

'use strict';

function mapProvider() {
  this.$get = ['appConfig', function (appConfig) {
    var mapboxAccessToken = appConfig.mapBoxToken;
    var mapboxUser = 'omerchehmer';
    var mapboxStyleId = 'ckqounyab12qd18pdtpxe2xn4';
    const mapboxsrc = 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js';
    const mapboxdrawsrc = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.js';
    function isScriptLoaded(scriptSrc) {
      const scriptElements = document.querySelectorAll('script');
      for (let i = 0; i < scriptElements.length; i++) {
          if (scriptElements[i].src === scriptSrc) {
              return true; // The script is already loaded
          }
      }
      return false; // The script is not loaded
  }
  function loadMapboxScripts() {
    return new Promise((resolve, reject) => {
      const mapboxScript = document.createElement('script');
      const mapboxDrawScript = document.createElement('script');
      mapboxScript.src = mapboxsrc;
      mapboxDrawScript.src = mapboxdrawsrc;
      const checkScriptsLoaded = () => {
        if (mapboxScript.loaded && mapboxDrawScript.loaded) {
          resolve();
        }
      };
      mapboxScript.addEventListener('load', function() {
        mapboxScript.loaded = true;
        checkScriptsLoaded();
      });

      mapboxDrawScript.addEventListener('load', function() {
        mapboxDrawScript.loaded = true;
        checkScriptsLoaded();
      });
      // Add an error event listener to handle script loading errors
      mapboxScript.addEventListener('error', reject);
      mapboxDrawScript.addEventListener('error', reject);

      // Append the scripts to the document to start loading them
      document.head.appendChild(mapboxScript);
      document.head.appendChild(mapboxDrawScript);
    });
  }


  function onScriptLoaded (containerName, callback) {
    const container = containerName || 'mapbox';
    if (mapboxgl) {
      mapboxgl.accessToken = mapboxAccessToken;
      const map = new mapboxgl.Map({
        container: container,
        style: 'mapbox://styles/' + mapboxUser + '/' + mapboxStyleId,
        zoom: 15,
      });
      callback && callback(map);
    } else {
      callback && callback(null);
    }
  }

    function createMap(containerName, callback) {
      if (!isScriptLoaded(mapboxsrc) || !isScriptLoaded(mapboxdrawsrc)) {
        loadMapboxScripts().then(function() {
          onScriptLoaded(containerName, callback);
        })
      } else {
        onScriptLoaded(containerName, callback);
      }
    }

    function createMapboxDraw() {
      return new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        },
        defaultMode: 'simple_select'
      });
    }

    function getPolygonBoundingBox (arr, bounds = [[], []]) {
      let polygon;
      let longitude;
      let latitude;
      for (var i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i][0][0])){
          bounds = getPolygonBoundingBox(arr[i], bounds);
        } else {
          polygon = arr[i];
          for (var j = 0; j < polygon.length; j++) {
            longitude = polygon[j][0];
            latitude = polygon[j][1];
            bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
            bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
            bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
            bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
          }
        }
      }
      return bounds;
    }

    function drawPolygon(map, coordinates, name) {
      name = name || '_polygon';
      if (map && coordinates) {
        const geojson = {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: coordinates}
          }
        };
        map.addSource(name, geojson);
        map.addLayer({
            id: name,
            type: 'fill',
            source: name,
            layout: {},
            paint: { 'fill-color': 'gray', 'fill-opacity': 0.3 },
        });
        const bounds = getPolygonBoundingBox(coordinates);
        map.fitBounds(bounds, {padding: 20});
      }
    }

    function getPolygonFromGooglePlace(googlePlace) {
      if (googlePlace) {
        const viewport = {
          northeast: {
            lat: googlePlace.geometry.viewport.getNorthEast().lat(),
            lng: googlePlace.geometry.viewport.getNorthEast().lng(),
          },
          southwest: {
            lat: googlePlace.geometry.viewport.getSouthWest().lat(),
            lng: googlePlace.geometry.viewport.getSouthWest().lng()
          }
        }
        return [
          [
            [viewport.southwest.lng, viewport.southwest.lat],
            [viewport.northeast.lng, viewport.southwest.lat],
            [viewport.northeast.lng, viewport.northeast.lat],
            [viewport.southwest.lng, viewport.northeast.lat],
            [viewport.southwest.lng, viewport.southwest.lat],
          ],
        ];
      }
      return null;
    }

    function drawStationPolygon (map, station, name) {
      name = name || '_polygon';
      if (map && station && station.type === "area" && station.polygon && station.polygon.coordinates) {
        const polygon = station.polygon.coordinates;
        drawPolygon(map, station.polygon.coordinates, name);
        return polygon;
      }
    }

    function removePolygon(map, name) {
      if (map.getLayer(name)) {
        map.removeLayer(name);
      }
      if (map.getSource(name)) {
        map.removeSource(name);
      }
    }
    function deleteAllShapesExceptLastOne(drawObject) {
      if (drawObject) {
        const data = drawObject.getAll();
        data.features.forEach(function (feature, index) {
          if (index !== data.features.length -1) {
            drawObject.delete(feature.id);
          }
        });
      } else {
        console.log('deleteAllShapesExceptLastOne - draw object is undefined');
      }
    }
    function deleteAllShapes(drawObject) {
      if (drawObject) {
        const data = drawObject.getAll();
        data.features.forEach(function (feature, index) {
          drawObject.delete(feature.id);
        });
      } else {
        console.log('deleteAllShapesExceptLastOne - draw object is undefined');
      }
    }

    function getStaticMapSrc(data) {
      const width = data.width || 1000;
      const height = data.height || 500;
      const zoom = data.zoom || 15;
      const accessToken = "access_token=" + mapboxAccessToken;
      const lngLatStr = data.loc ? data.loc[0] + ',' + data.loc[1] : '';
      const widthHeight = "" + width + 'x' + height;
      const res = "https://api.mapbox.com/styles/v1/" + mapboxUser +"/" + mapboxStyleId + "/static/pin-s-l+000(" + lngLatStr + ")/" + lngLatStr + "," + zoom + "/" + widthHeight + "?" + accessToken;
      //   '&addlayer={"type":"fill","source":{"type":"geojson","data":{"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":' + JSON.stringify(data.polygon) + '}},"layout":{},"paint":{"fill-color":"#0080FF","fill-opacity":0.7}}';
      return res;
    }
    function removeAllLayers(map) {
      var mapStyle = map.getStyle();

      if (mapStyle && mapStyle.layers) {
        mapStyle.layers.forEach(function (layer) {
          if (layer.id !== 'background' && layer.id !== 'terrain-data') {
              map.removeLayer(layer.id);
          }
        });
      }
    }
    function removeAllSources(map) {
      var sources = map && map.style && map.style.sourceCaches && Object.keys(map.style.sourceCaches);

      // Iterate through the sources and remove them
      if (sources) {
          sources.forEach(function (source) {
              map.removeSource(source);
          });
      }
    }
    function createMapDOM(containerId, mapId, condition) {
      const mapContainer = document.getElementById(containerId);
      if (mapContainer) {
        mapContainer.innerHTML = '';
        mapContainer.insertAdjacentHTML('beforeend', '<div id="' + mapId + '" ng-show="' + condition + '" style="width: 1000px;height: 500px;"></div>');
        const map = document.getElementById(mapId);
        return map;
      }
      return null;
    }
    return {
      createMap: createMap,
      createMapboxDraw: createMapboxDraw,
      getPolygonBoundingBox: getPolygonBoundingBox,
      drawPolygon: drawPolygon,
      getPolygonFromGooglePlace: getPolygonFromGooglePlace,
      drawStationPolygon: drawStationPolygon,
      removePolygon: removePolygon,
      deleteAllShapesExceptLastOne: deleteAllShapesExceptLastOne,
      deleteAllShapes: deleteAllShapes,
      getStaticMapSrc: getStaticMapSrc,
      removeAllLayers: removeAllLayers,
      removeAllSources: removeAllSources,
      createMapDOM: createMapDOM,
    };
  }];
}
